import Swal from "sweetalert2";
// import numeral from "numeral";

export const globalMixin = {
  components: {},
  data: () => ({
    alert: {
      state: false,
      mode: "error",
      msg: "It seems an error occurred, please try again...",
    },
    error: {
      state: false,
      msg: "It seems an error occurred, please try again...",
    },
  }),
  computed: {},
  methods: {
    showError(msg) {
      clearTimeout(this.timeout);
      this.alert.state = true;

      if (msg.toString().includes("timeout"))
        this.alert.msg =
          "Sorry, you seem to be on a slow network. Kindly check your network and try again.";
      else this.alert.msg = msg;

      this.alert.mode = "error";
      this.timeout = setTimeout(() => {
        this.alert.state = false;
      }, 10000);
    },
    showSuccess(msg) {
      clearTimeout(this.timeout);
      this.alert.state = true;
      this.alert.msg = msg;
      this.alert.mode = "success";
      this.timeout = setTimeout(() => {
        this.alert.state = false;
      }, 10000);
    },
    handleError(err) {
      // console.log(err);
      if (err == "Network Error" || err.toString().includes("timeout")) {
        console.log(err);
      } else {
        Swal.fire(err, {
          icon: "error",
        });
      }
    },
    currencyFormat2(value) {
      if (typeof value !== "number") {
        return value;
      } else {
        var formatter = new Intl.NumberFormat("NGN", {
          style: "currency",
          currency: "NGN",
          currencySign: "accounting",
          currencyDisplay: "symbol",
        });
        return formatter.format(value).replace("NGN", "₦");
      }
    },
    currencyFormat(value) {
      if (typeof value !== "number") {
        return value;
      } else {
        var formatter = new Intl.NumberFormat("NGN", {
          style: "currency",
          currency: "NGN",
          currencySign: "accounting",
          currencyDisplay: "symbol",
        });
        return formatter.format(value).replace("NGN", "₦");
      }
    },
  },
};
