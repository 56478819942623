<template>
  <div
    v-if="!viewRates"
    class="sm:shadow-md sm:p-6 sm:rounded-sm md:w-1/2 sm:h-102 overflow-y-auto w-full bg-white flex flex-col animate__animated animate__zoomIn animate__faster"
  >
    <h1 class="text-xl font-semibold text-gray-600">Manage Stamp Duty rates</h1>
    <p class="text-gray-500 mb-4 text-md">
      Enter rate details and save or cancel to view rates
    </p>

    <vee-form
      @submit="createRate"
      class="w-full mt-0"
      :validation-scheme="validationScheme"
      v-slot="{ errors }"
    >
      <div class="py-4">
        <div class="mb-6">
          <label
            for="recovery_tin"
            class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
            >Stamp Duty Type</label
          >
          <select
            v-model="rateDetails.type"
            id="rate_type"
            class="block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500"
            name="rate_type"
          >
            <option value="">Select a type</option>
            <option value="advalorem">Advalorem</option>
            <option value="flat">Flat</option>
          </select>
        </div>
        <div class="mb-6">
          <label
            for="rate_name"
            class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
            >Stamp Duty Rate Name</label
          >
          <vee-field
            type="text"
            name="rate_name"
            id="rate_name"
            :rule="validationScheme.required"
            v-model="rateDetails.levy_name"
            placeholder="Enter rate name"
            class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
          />
          <span class="text-error">{{ errors.rate_name }}</span>
        </div>
        <div class="mb-8">
          <label
            for="confirm_password"
            class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
            >Stamp Duty Rate</label
          >
          <vee-field
            type="text"
            name="duty_rate"
            id="duty_rate"
            :rule="validationScheme.required"
            v-model="rateDetails.rate"
            placeholder="Enter rate"
            class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
          />
          <span class="text-error">{{ errors.duty_rate }}</span>
        </div>
        <div class="mt-0">
          <button
            type="submit"
            :disabled="creatingRate"
            class="w-full transition duration-200 ease-in text-white bg-primary border-0 py-2 px-8 focus:outline-none hover:bg-dark rounded text-lg font-medium mt-2 mb-4"
          >
            <div v-if="!creatingRate">Save</div>
            <div v-else class="flex items-center space-x-4 justify-center">
              <i class="fas fa-circle-notch animate-spin"></i>
              <span>Please wait...</span>
            </div>
          </button>

          <button
            @click="cancelOperation"
            class="w-full text-center text-red-500 hover:text-red-600 cursor-pointer outline-none"
          >
            Cancel
          </button>
        </div>
      </div>
    </vee-form>
  </div>
  <div
    v-else
    class="sm:shadow-md sm:p-6 sm:rounded-sm sm:w-3/5 sm:h-102 overflow-y-auto overflow-x-hidden hide-scrollbar w-full bg-white flex flex-col animate__animated animate__zoomIn animate__faster"
  >
    <h1 class="text-xl font-semibold text-gray-600">Manage Stamp Duty rates</h1>

    <div>
      <div class="py-8">
        <div class="flex flex-row mb-1 sm:mb-0 justify-between w-full">
          <div class="w-full">
            <div
              class="flex flex-col md:flex-row w-full md:space-x-3 space-y-3 md:space-y-0 justify-center"
            >
              <input
                v-model="rateName"
                type="text"
                id=""
                class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
                placeholder="name"
              />

              <button
                @click="viewRates = false"
                class="flex-shrink-0 px-4 py-2 text-base font-semibold text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-200"
                type="submit"
              >
                New
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="loadingRates"
          class="w-full h-full flex items-center text-center"
        >
          <i
            :class="`lg:focus:shadow-sm w-12 mx-auto text-center fas fa-circle-notch mt-4 text-3xl text-blue-primary animate-spin`"
          ></i>
        </div>
        <div v-else class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-y-auto">
          <div
            class="inline-block min-w-full shadow rounded-lg overflow-hidden"
          >
            <table class="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  >
                    Rate
                  </th>

                  <th
                    scope="col"
                    class="px-5 py-3 bg-white border-b border-gray-200 text-gray-800 text-left text-sm uppercase font-normal"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="rate in duty_rates" :key="rate.stampDutyId">
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ rate.levy_name }}
                    </p>
                  </td>
                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                  >
                    <p class="text-gray-900 whitespace-no-wrap">
                      {{ rate.rate }}
                    </p>
                  </td>

                  <td
                    class="px-5 py-5 border-b border-gray-200 bg-white text-sm sm:space-x-3"
                  >
                    <button
                      @click="editRate(rate)"
                      class="text-blue-600 hover:text-blue-900 cursor-pointer outline-none"
                    >
                      Edit
                    </button>
                    <button
                      @click="deleteRate(rate)"
                      class="text-red-600 hover:text-red-900 cursor-pointer outline-none"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Axios } from "@/helpers/axios-config";
// import { username } from "@/helpers/login_details.js";
import { globalMixin } from "@/mixins/global.js";
import Swal from "sweetalert2";

// import Swal from "sweetalert2";;
export default {
  mixins: [globalMixin],
  data: () => ({
    validationScheme: { required: "required" },
    creatingRate: false,
    editingRate: false,
    loadingRates: true,
    viewRates: true,
    rateDetails: { levy_name: "", rate: "", type: "advalorem" },
    rateName: "",
  }),
  computed: {
    duty_rates() {
      return this.$store.getters["stamp_duty/duty_rates"].filter((rate) => {
        return rate.levy_name
          .toLowerCase()
          .includes(this.rateName.toLowerCase());
      });
    },
  },
  methods: {
    cancelOperation() {
      this.viewRates = true;
      this.rateDetails = { levy_name: "", rate: "", type: "Advalorem" };
    },
    async loadRates() {
      this.loadingRates = true;
      await this.$store.dispatch("stamp_duty/getDutyRates");
      this.loadingRates = false;
    },
    async createRate() {
      try {
        this.creatingRate = true;
        let result;

        if (this.rateDetails.stampDutyId) {
          let id = this.rateDetails.stampDutyId;
          delete this.rateDetails.stampDutyId;
          result = await Axios.put(`stampduty/rates/${id}`, this.rateDetails);
        } else {
          result = await Axios.post(`stampduty/rates`, this.rateDetails);
        }

        const { status, msg, message } = result.data;

        if (status === "success") {
          Swal.fire("Revenue House", msg || message, "success");
          this.loadRates();
          this.cancelOperation();
        } else {
          Swal.fire({
            icon: "error",
            text: message || msg,
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.creatingRate = false;
      }
    },
    async editRate(rate) {
      try {
        this.rateDetails = rate;
        this.viewRates = false;
      } catch (err) {
        console.log(err);
      }
    },
    async deleteRate(rate) {
      try {
        let answer = await Swal.fire({
          icon: "warning",
          title: "Revenue House",
          text: "are you sure?",
          buttons: ["No please", "Yes, go ahead"],
        });

        if (answer) {
          let result = await Axios.delete(
            `stampduty/rates/${rate.stampDutyId}`
          );

          const { status, msg, message } = result.data;

          if (status === "success") {
            Swal.fire("Revenue House", msg, "success");
            this.loadRates();
          } else {
            Swal.fire({
              icon: "error",
              text: message | msg,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  async created() {
    await this.loadRates();
  },
};
</script>

<style>
.fade-2-enter-active,
.fade-2-leave-active {
  transition: all 0.5s ease;
}

.fade-2-enter-from,
.fade-2-leave-to {
  opacity: 0px;
  transform: translateX(20px);
}

.fade-2-move {
  position: absolute !important;
}
</style>
