<template>
  <content-holder :title="'Account settings'" class="p-6">
    <alert-banner
      v-if="alert.state"
      :alert="alert"
      @close="alert.state = false"
    ></alert-banner>
    <div
      class="h-full items-start overflow-y-auto flex flex-col sm:flex-row justify-start sm:space-x-6 mt-10 sm:mt-0 sm:p-2"
    >
      <div
        class="sm:shadow-md sm:p-6 sm:rounded-sm md:w-1/2 sm:h-102 w-full bg-white flex flex-col"
      >
        <!-- header -->

        <h1 class="text-3xl font-semibold text-gray-600">
          Reset your password
        </h1>
        <p class="text-gray-500 mb-2">Enter new password and confirm</p>
        <!-- <p v-if="linkErrorMessage.length > 0" class="error-text2">
          {{ linkErrorMessage }}
        </p>
        <p v-if="linkSuccessMessage.length > 0" class="success-text2 text-lg">
          {{ linkSuccessMessage }}
        </p> -->
        <!-- sign-in -->
        <div class="py-4">
          <div class="mb-6">
            <label
              for="recovery_tin"
              class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
              >Current Password</label
            >
            <input
              type="password"
              name="current_password"
              id="current_password"
              v-model="userDetails.current_password"
              @focus="linkErrorMessage = ''"
              placeholder="Your new password"
              class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
            />
          </div>
          <div class="mb-6">
            <label
              for="recovery_tin"
              class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
              >New Password</label
            >
            <input
              type="password"
              name="new_password"
              id="new_password"
              v-model="userDetails.new_password"
              @focus="linkErrorMessage = ''"
              placeholder="Your new password"
              class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
            />
          </div>
          <div class="mb-8">
            <label
              for="confirm_password"
              class="block mb-2 text-sm text-gray-600 dark:text-gray-400"
              >Confirm Password</label
            >
            <input
              type="password"
              name="confirm_password"
              id="confirm_password"
              v-model="userDetails.confirm_password"
              @focus="linkErrorMessage = ''"
              @keypress.enter="resetPassword"
              placeholder="Confirm your password"
              class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-gray-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
            />

            <span
              v-if="userDetails.new_password != userDetails.confirm_password"
              class="error-text"
              >Passwords do not match</span
            >
          </div>
          <div class="mt-0">
            <button
              @click="resetPassword"
              :disabled="updatingPassword"
              class="w-full transition duration-200 ease-in text-white bg-primary border-0 py-2 px-8 focus:outline-none hover:bg-dark rounded text-lg font-medium mt-2"
            >
              <div v-if="!updatingPassword">Submit</div>
              <div v-else class="flex items-center space-x-4 justify-center">
                <i class="fas fa-circle-notch animate-spin"></i>
                <span>Please wait...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <stamp-duty-settings
        v-if="canViewStampDutySettings"
      ></stamp-duty-settings>
    </div>
  </content-holder>
</template>

<script>
import ContentHolder from "../components/ContentHolder.vue";
import { username, unit, role } from "@/helpers/login_details.js";
import AlertBanner from "@/components/AlertBanner.vue";
import { globalMixin } from "@/mixins/global.js";
// import Swal from "sweetalert2";;
import StampDutySettings from "../components/stamp_duty/admin/Settings.vue";

// import Swal from "sweetalert2";;
export default {
  components: {
    ContentHolder,
    AlertBanner,
    StampDutySettings,
  },
  mixins: [globalMixin],
  data: () => ({
    updatingPassword: false,
    unit,
    role,
    linkErrorMessage: "",
    linkSuccessMessage: "",
    userDetails: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
  }),

  computed: {
    canViewStampDutySettings() {
      if (unit === "stampduty" && role === "unithead") return true;
      else if (unit === "administrator" && role === "administrator")
        return true;

      return false;
    },
  },

  methods: {
    async resetPassword() {
      try {
        if (
          this.userDetails.current_password !== "" &&
          this.userDetails.new_password !== ""
        ) {
          this.updatingPassword = true;
          let result = await this.$store.dispatch("updatePassword", {
            currentPassword: this.userDetails.current_password,
            newPassword: this.userDetails.new_password,
            user: username,
          });
          if (result === "success") this.showSuccess("Password updated!");
          this.updatingPassword = false;
          this.userDetails = {
            current_password: "",
            new_password: "",
            confirm_password: "",
          };
        } else {
          this.showError("Please enter your current and new password....");
        }
      } catch (error) {
        console.log(error);
        if (error.toLowerCase().includes("error"))
          this.showError(error.substr(6, error.length));
        this.updatingPassword = false;
      }
    },
  },
};
</script>

<style></style>
